<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ name }}</span>
          </b-card-title>
        </b-card-header>
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Name')"
                  label-for="register-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Detail')"
                  label-for="register-detail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Detail"
                    vid="detail"
                    rules="required"
                  >
                    <b-form-input
                      id="register-detail"
                      v-model="detail"
                      name="register-detail"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Detail')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Over Table')"
                  label-for="overTable"
                >
                  <cleave
                    id="price"
                    v-model="overTable"
                    class="form-control"
                    :raw="false"
                    :options="{ numeral: true }"
                    placeholder="0"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- refId -->
                <b-form-group
                  :label="$t('Table')"
                  label-for="register-table"
                >
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="table"
                    :fields="tableColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(start)="data">
                      <cleave
                        id="start"
                        v-model="table[data.index].start"
                        class="form-control"
                        :raw="false"
                        :options="{ numeral: true }"
                        placeholder="0"
                      />
                    </template>
                    <template #cell(end)="data">
                      <cleave
                        id="end"
                        v-model="table[data.index].end"
                        class="form-control"
                        :raw="false"
                        :options="{ numeral: true }"
                        placeholder="0"
                      />
                    </template>
                    <template #cell(price)="data">
                      <cleave
                        id="price"
                        v-model="table[data.index].price"
                        class="form-control"
                        :raw="false"
                        :options="{ numeral: true }"
                        placeholder="0"
                      />
                    </template>
                    <template #cell(action)="data">
                      <b-button
                        variant="primary"
                        class="mr-2"
                        @click="addData()"
                      >
                        {{ $t('Add') }}
                      </b-button>
                      <b-button
                        v-if="table.length > 0"
                        variant="danger"
                        class="mr-2"
                        @click="removeData(data.index)"
                      >
                        {{ $t('Remove') }}
                      </b-button>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action button -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="validationForm()"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/eCommerce/shiping'

const STORE_MODULE_NAME = 'shiping'
export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BButton,
    BOverlay,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      name: '',
      detail: '',
      table: [], // ตารางการจัดส่ง
      overTable: 0,
      type: 'order',
      required,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'start', label: this.$t('Start'), sortable: false },
        { key: 'end', label: this.$t('End'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.show = false
      const type = to.fullPath.split('/')
      this.type = type[type.length - 1]
      if (this.$route.params.id !== 'add') {
        const obj = {
          id: this.$route.params.id,
          type: this.type,
        }

        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/view`, obj)
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            const respData = result.data.data
            this.selectedData(respData)
            //
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            // eslint-disable-next-line no-unused-vars
          })
          .catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      } else {
        this.addData()
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const type = this.$route.path.split('/')
    this.type = type[type.length - 1]
    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
        type: this.type,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    } else {
      this.addData()
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    addData() {
      this.table.push({
        start: 0,
        end: 0,
        price: 0,
      })
    },
    removeData(index) {
      this.table.splice(index, 1)
    },
    selectedData(data) {
      this.dataId = data._id
      this.name = data.name
      this.detail = data.detail
      this.table = data.table
      this.overTable = data.overTable
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(success => {
              if (success) {
                const obj = {
                  id: this.dataId,
                  name: this.name,
                  detail: this.detail,
                  table: this.table.map(e => ({
                    start: e.start.replaceAll(',', ''),
                    end: e.end.replaceAll(',', ''),
                    price: e.price.replaceAll(',', ''),
                  })),
                  overTable: this.overTable,
                  type: this.type,
                }
                if (obj.id !== null) {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                    .then(result => {
                      this.show = false
                      this.$router.push({
                        path: `/e-commerce/shiping/${this.type}`,
                      })
                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    })
                    .catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                } else {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                    .then(result => {
                      this.show = false

                      this.$router.push({
                        path: `/e-commerce/shiping/${this.type}`,
                      })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    })
                    .catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$router.push({ path: '/hero/partner/template/time' })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
